import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = ({ items, unit }) => ({
  indexAxis: "y",
  categoryPercentage: 0.9,
  barPercentage: 0.7,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      anchor: "end",
      align: "left",
      formatter: function (value, context) {
        return value.toLocaleString();
      },
    },
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: ` ${items}  (${unit})`,
    },
  },
});

const colorsChart = ["#cdb4db", "#ffc8dd", "#ffafcc", "#bde0fe", "#a2d2ff"];

export const data = ({ data }) => {
  const datasetsArr = data?.map((el) => el.val);
  const labelArr = [];

  datasetsArr?.forEach((perEl) => {
    perEl?.forEach((el) => {
      const labelIndx = labelArr.findIndex((elIndx) => el.year === elIndx.year);
      if (labelIndx < 0) {
        labelArr.push({ year: el.year, value: [parseFloat(el.value)?.toFixed(2) || 0] });
      } else {
        labelArr[labelIndx].value.push(parseFloat(el.value)?.toFixed(2));
      }
    });
  });

  return {
    labels: data.map((el) => el.countryName),
    datasets: labelArr.map((el, indx) => ({
      label: el.year,
      data: el.value,
      backgroundColor: colorsChart[indx],
      borderRadius: 3,
    })),
  };
};
