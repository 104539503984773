import { useQuery } from "@apollo/client";
import { Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getItemsQuery,
  getCountriesQuery,
  getChartDataQuery,
  filterCartVar,
  getFilterDataQuery,
  getTotalItemsQuery,
} from "../utils/apolloQueries";
import { removeDuplicate, makeArrFromOpj } from "../utils/helperFunctions";
import Header from "../components/Header";
import ContainerBox from "../components/ContainerBox";
import ChartArea from "../components/ChartArea";
import SelectonArea from "../components/SkeletonArea";
import ControlArea from "../components/ControlArea.js";
import FlyBtn from "../components/Inputs/FlyBtn";
import GlossaryFile from "../docs/glossary.pdf";

export default function Climate() {
  //*variables
  const [itemsVal, setItemsVal] = useState([]);
  const [itemsArr, setItemsArr] = useState([]);
  const [countriesVal, setCountriesVal] = useState([]);
  const [countriesArr, setCountriesArr] = useState([]);
  const [chartApiData, setChartApiData] = useState([]);
  const [filtersAreaArr, setFiltersAreaArr] = useState([]);
  const [chosenClimate, setChosenClimate] = useState([]);
  const [chosenRcp, setChosenRcp] = useState([]);
  const [chosenPolicy, setChosenPolicy] = useState([]);
  const [chosenVariable, setChosenVariable] = useState([]);
  const [chosenSsp, setChosenSsp] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [compareChartsData, setCompareChartsData] = useState([]);
  const [isClimateCompared, setIsClimateCompared] = useState(false);
  const [isPolicyCompared, setIsPolicyCompared] = useState(false);

  //* google analytics
  useEffect(() => {
    console.log(
      itemsVal,
      countriesVal,
      chosenClimate,
      chosenVariable,
      chosenPolicy,
      chosenRcp,
      chosenSsp
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Selection",
      object: {
        items: itemsVal,
        countries: countriesVal,
        climate: chosenClimate,
        Variable: chosenVariable,
        policy: chosenPolicy,
        rcp: chosenRcp,
        ssp: chosenSsp,
      },
    });
  }, [
    itemsVal,
    countriesVal,
    chosenClimate,
    chosenVariable,
    chosenPolicy,
    chosenRcp,
    chosenSsp,
  ]);
  
  //*call api req

  //call items
  const {
    data: itemsRes,
    error,
    loading: itemsLoading,
  } = useQuery(getItemsQuery, {
    variables: { skip: !totalItems, limit: totalItems },
  });

  const {
    data: countryRes,
    error: countryError,
    loading: countryLoading,
  } = useQuery(getCountriesQuery);

  const { data: totalItemsRes } = useQuery(getTotalItemsQuery);

  // call countries
  const { data: chartDataRes, loading: chartDataLoading } = useQuery(
    getChartDataQuery,
    {
      variables: filterCartVar(
        itemsVal,
        countriesVal,
        chosenClimate,
        chosenVariable,
        chosenPolicy,
        chosenRcp,
        chosenSsp
      ),
    }
  );

  useEffect(() => {
    setTotalItems(totalItemsRes?.climate_aggregated[0]?.count?.id);
  }, [itemsRes, totalItemsRes]);

  useEffect(() => {
    const comperedValsArr = [];
    const comberClimateArr = [];
    compareChartsData.forEach((chartDataEl) => {
      //  add
      comberClimateArr.push(chartDataEl);
      if (
        chartDataEl.climate === "No Climate Change" ||
        chartDataEl.policy === "Baseline (BAU)"
      ) {
        comperedValsArr.push(chartDataEl);
      }

      const noClimateChangeDateVal = comperedValsArr?.find(
        (noClimateEl) =>
          noClimateEl.items === chartDataEl.items &&
          noClimateEl.climate === "No Climate Change" &&
          chartDataEl.policy === noClimateEl.policy &&
          chartDataEl.rcp === noClimateEl.rcp &&
          chartDataEl.variable === noClimateEl.variable &&
          chartDataEl.ssp === noClimateEl.ssp
      );

      //   ********************** climate compare ******************************* //

      noClimateChangeDateVal?.data &&
        isClimateCompared &&
        chartDataEl.climate !== "No Climate Change" &&
        comberClimateArr.push({
          ...chartDataEl,
          unit: "%",
          items: ` ${chartDataEl.items}  ${chartDataEl.climate}  Climate Change Impact `,
          compare: "climate",
          data: chartDataEl?.data?.map((climateDataEl, climateDataIndx) => ({
            countryName:
              noClimateChangeDateVal?.data[climateDataIndx].countryName,
            val: climateDataEl?.val.map((valEl, climateValIndx) => {
              const climateChangeVal =
                noClimateChangeDateVal?.data[climateDataIndx]?.val[
                  climateValIndx
                ]?.value;

              return {
                // change = ((CC ÷ NoCC) - 1) × 100
                // CC = GFDL, HGEM or IPSL values
                value: ((valEl.value / climateChangeVal - 1) * 100)?.toFixed(3),
                year: noClimateChangeDateVal?.data[climateDataIndx]?.val[
                  climateValIndx
                ].year,
              };
            }),
          })),
        });

      //   ********************** policy compare ******************************* //
      const policyDateVal = comperedValsArr?.find(
        (policyEl) =>
          policyEl.items === chartDataEl.items &&
          policyEl.policy === "Baseline (BAU)" &&
          chartDataEl.climate === policyEl.climate &&
          chartDataEl.rcp === policyEl.rcp &&
          chartDataEl.variable === policyEl.variable &&
          chartDataEl.ssp === policyEl.ssp
      );

      policyDateVal?.data &&
        isPolicyCompared &&
        chartDataEl.policy !== "Baseline (BAU)" &&
        comberClimateArr.push({
          ...chartDataEl,
          unit: "%",
          items: ` ${chartDataEl.items}  ${chartDataEl.policy}  Policy Intervention Impact `,
          compare: "policy",

          data: chartDataEl?.data?.map((climateDataEl, climateDataIndx) => {
            return {
              countryName: policyDateVal?.data[climateDataIndx].countryName,

              val: climateDataEl?.val?.map((valEl, climateValIndx) => {
                const policyVal =
                  policyDateVal?.data[climateDataIndx]?.val[climateValIndx]
                    ?.value;
                return {
                  // ((Policy/BAU)-1)100
                  value: ((valEl.value / policyVal - 1) * 100)?.toFixed(3),
                  year: policyDateVal?.data[climateDataIndx]?.val[
                    climateValIndx
                  ].year,
                };
              }),
            };
          }),
        });
    });

    setChartApiData(comberClimateArr);
  }, [compareChartsData, isClimateCompared, isPolicyCompared]);

  const { data: climateVarsRes, loading: climateVarsLoading } = useQuery(
    getFilterDataQuery,
    {
      variables: { skip: !totalItems, limit: totalItems },
    }
  );

  //* set data
  // change data arr to chart js
  useEffect(() => {
    const chartRes = chartDataRes?.climate;
    if (!chartRes) return;

    let newData = [];

    for (let i = 0; i < chartRes.length; i++) {
      const itemIndx = newData.findIndex(
        (el) =>
          el.items === chartRes[i]?.items &&
          el.climate === chartRes[i]?.climate &&
          el.climate === chartRes[i]?.climate &&
          el.policy === chartRes[i]?.policy_intervention &&
          el.rcp === chartRes[i]?.rcp &&
          el.ssp === chartRes[i]?.ssp &&
          el.variable === chartRes[i]?.variable
      );

      if (itemIndx < 0) {
        newData.push({
          items: chartRes[i].items,
          id: chartRes[i].id,
          unit: chartRes[i].unit,
          variable: chartRes[i].variable,
          policy: chartRes[i].policy_intervention,
          rcp: chartRes[i].rcp,
          climate: chartRes[i].climate,
          ssp: chartRes[i].ssp,
          data: [
            {
              countryName: chartRes[i].country.country_name,
              val: [
                {
                  value: chartRes[i].value,
                  year: new Date(chartRes[i].year).getUTCFullYear(),
                },
              ],
            },
          ],
        });
      } else {
        const dataIndx = newData[itemIndx].data.findIndex(
          (el) => el.countryName === chartRes[i].country.country_name
        );
        if (dataIndx < 0) {
          newData[itemIndx].data = [
            ...newData[itemIndx].data,
            {
              countryName: chartRes[i].country.country_name,
              val: [
                {
                  value: chartRes[i].value,
                  year: new Date(chartRes[i].year).getUTCFullYear(),
                },
              ],
            },
          ];
        } else if (
          !newData[itemIndx].data?.includes([
            new Date(chartRes[i].year).getUTCFullYear(),
            chartRes[i].value,
          ])
        ) {
          newData[itemIndx].data[dataIndx].val = [
            ...newData[itemIndx].data[dataIndx].val,
            {
              value: chartRes[i].value,
              year: new Date(chartRes[i].year).getUTCFullYear(),
            },
          ];
        }
      }
    }
    setCompareChartsData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDataRes]);

  //* get items from api
  useEffect(() => {
    if (!itemsRes?.climate) return;
    setItemsArr(removeDuplicate(itemsRes?.climate, "items"));
  }, [error, itemsRes, itemsLoading]);

  //* get country from api
  useEffect(() => {
    if (!countryRes?.country) return;
    setCountriesArr(makeArrFromOpj(countryRes?.country, "country_name"));
  }, [countryError, countryLoading, countryRes]);

  useEffect(() => {
    if (!climateVarsRes?.climate) return;
    const climateFilter = [
      "climate",
      "variable",
      "SSP",
      "policy_intervention",
      "RCP",
    ].map((typeItem) => {
      const uniqArr = removeDuplicate(
        climateVarsRes?.climate,
        typeItem.toLowerCase()
      );

      return {
        label: typeItem,
        optionsArr: uniqArr,
      };
    });

    setFiltersAreaArr(climateFilter);
  }, [climateVarsRes]);

  // update state management

  const itemsChangeHandel = (val) => {
    setItemsVal(val);
  };
  const countriesValChangeHandel = (val) => {
    setCountriesVal(val);
  };

  const climateValChangeHandel = (val, label) => {
    switch (label) {
      case "climate":
        setChosenClimate(val);
        break;
      case "variable":
        setChosenVariable(val);
        break;
      case "policy_intervention":
        setChosenPolicy(val);
        break;
      case "RCP":
        setChosenRcp(val);
        break;
      case "SSP":
        setChosenSsp(val);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (
      !chartApiData.find((el) => el.climate !== "No Climate Change") ||
      !chartApiData.find((el) => el.climate === "No Climate Change")
    ) {
      setIsClimateCompared(false);
    }
    if (
      !chartApiData.find((el) => el.policy !== "Baseline (BAU)") ||
      !chartApiData.find((el) => el.policy === "Baseline (BAU)")
    ) {
      // setIsPolicyCompared(false);
    }
  }, [chartApiData]);
  return (
    <>
      <Header />

      <ContainerBox>
        <Button
          variant="contained"
          onClick={() => {
            window.open(GlossaryFile);
          }}
          sx={{
            margin: "30px 0 0 auto ",
            display: "block",
            textTransform: "capitalize",
            width: { xs: "100%", md: "200px" },
          }}
        >
          Glossary
        </Button>

        <ControlArea
          countriesArr={countriesArr}
          countriesValChangeHandel={countriesValChangeHandel}
          itemsArr={itemsArr}
          itemsChangeHandel={itemsChangeHandel}
          climateValChangeHandel={climateValChangeHandel}
          filtersAreaArr={filtersAreaArr}
          compare={{
            handelChangeCompare: (val, label) => {
              if (label === "climate") {
                setIsClimateCompared(val);
                val && setIsPolicyCompared(false);
              }
              if (label === "policy_intervention") {
                setIsPolicyCompared(val);
                val && setIsClimateCompared(false);
              }
            },
            vals: {
              climate: isClimateCompared,
              policy_intervention: isPolicyCompared,
            },
            disableCompare: {
              climate:
                !chartApiData.find(
                  (el) => el.climate !== "No Climate Change"
                ) ||
                !chartApiData.find((el) => el.climate === "No Climate Change"),

              policy_intervention:
                !chartApiData.find((el) => el.policy !== "Baseline (BAU)") ||
                !chartApiData.find((el) => el.policy === "Baseline (BAU)"),
            },
          }}
        />

        <Grid
          container
          rowSpacing={5}
          columnSpacing={{
            md: 6,
          }}
        >
          {chartDataLoading || itemsLoading || climateVarsLoading ? (
            <SelectonArea />
          ) : (
            <ChartArea chartDataArr={chartApiData} />
          )}
        </Grid>

        <FlyBtn />
      </ContainerBox>
    </>
  );
}
