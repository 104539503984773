import { Grid, Skeleton } from "@mui/material";

const SelectonArea = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((el, indx) => {
        return (
          <Grid
            key={"el" + el * indx}
            item
            md={6}
            lg={4}
            sx={{ width: "100%" }}
          >
            <Skeleton variant="rounded" height={350} />
          </Grid>
        );
      })}
    </>
  );
};

export default SelectonArea;
