import { Bar } from "react-chartjs-2";
import { options, data } from "../utils/chart.config";
import { Paper, Grid, Typography, Stack, Box } from "@mui/material";

const ChartArea = (props) => {
  return (
    <>
      {props.chartDataArr.length ? (
        props.chartDataArr.map((el) => {
          return (
            <Grid key={el.id + el.items} item md={6} lg={4} sx={{ width: "100%" }}>
              <Paper elevation={3} sx={{
                  background : el?.compare ==="climate" && "#f7f7f7" , 
                  backgroundColor : el?.compare ==="policy" && "#fffcf6" , 
              
              ...cardStyle}}>
                <Bar options={options(el)} height={"100%"} data={data(el)} />
              </Paper>

              <Box sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    <b>Climate:</b> {el.climate} 
                  </Typography>
                  <Typography>
                    <b>SSP:</b> {el.ssp} 
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>
                    <b>Variable:</b> {el.variable} 
                  </Typography>
                  <Typography>
                    <b>RCP:</b> {el.rcp} 
                  </Typography>
                </Stack>
                <Typography>
                  <b>Policy intervention:</b> {el.policy} 
                </Typography>
              </Box>
            </Grid>
          );
        })
      ) : (
        <Typography
          variant="h4"
          sx={{ p: 7, textAlign: "center", width: "100%" }}
        >
          No data available
        </Typography>
      )}
    </>
  );
};

export default ChartArea;

const cardStyle = {
  p: 3,
  height: "450px",
  transition: ".4s",
  "&:hover": { boxShadow: 8 },
};
