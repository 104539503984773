import { gql } from "@apollo/client";

export const getTotalItemsQuery = gql`
  query Climate {
    climate_aggregated {
      count {
        id
      }
    }
  }
`;

export const getItemsQuery = gql`
  query Climate($limit: Int) {
    climate(limit: $limit) {
      items
    }
  }
`;

export const getCountriesQuery = gql`
  query Query {
    country {
      country_name
    }
  }
`;

export const getFilterDataQuery = gql`
  query Climate($limit: Int) {
    climate(limit: $limit) {
      climate
      variable
      policy_intervention
      rcp
      ssp
    }
  }
`;

export const getChartDataQuery = gql`
  query Climate($filter: climate_filter) {
    climate(filter: $filter) {
      id
      unit
      year
      value
      items
      country {
        country_name
      }
      variable
      policy_intervention
      rcp
      climate
      ssp
    }
  }
`;

export const filterCartVar = (
  itemArr,
  countrySArr,
  climateArr,
  variableArr,
  policyArr,
  rcpArr,
  sspArr
) => ({
  skip:
    !itemArr.length ||
    !itemArr.length ||
    !countrySArr.length ||
    !policyArr.length ||
    !rcpArr.length ||
    !sspArr.length ||
    !variableArr.length,
  filter: {
    items: {
      _in: itemArr,
    },
    country: {
      country_name: {
        _in: countrySArr,
      },
    },
    climate: {
      _in: climateArr,
    },
    ssp: {
      _in: sspArr,
    },
    rcp: {
      _in: rcpArr,
    },
    policy_intervention: {
      _in: policyArr,
    },
    variable: {
      _in: variableArr,
    },
  },
});
