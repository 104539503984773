import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FFD18D",
      contrastText: "#000",
    },

    secondary: {
      main: "#89A527",
      contrastText: "#fff",
    },
  },
});

export default theme;
