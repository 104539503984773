import { Button, Stack } from "@mui/material";

export default function FlyBtn() {
  const handleOpenNewTap = (url) => {
    window.open(url, "_blank");
  };

  const bottomBtnsArr = [
    {
      
      name: "Impact Model",
      url: "https://www.ifpri.org/project/ifpri-impact-model",
      des: "Foresight Modeling with IFPRI's Impact Model",
    },
    {
      name: "Reference Study",
      url: "https://www.ifpri.org/publication/climate-resilience-policies-and-investments-egypts-agriculture-sector-sustaining",
      des: "Climate-resilience policies and investments for Egypt’s agriculture sector: Sustaining productivity and food security",
    },
  ];

  return (
    <Stack
      spacing={2}
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent="flex-end"
      alignItems="center"
      my={4}
    >
      {bottomBtnsArr.map((el) => (
        <Button
          key={el.name + el.url}
          variant="contained"
          onClick={() => handleOpenNewTap(el.url)}
          sx={{
            textTransform: "capitalize",
            width: { xs: "100%", md: "200px" },
          }}
        >
          {el.name}
        </Button>
      ))}
    </Stack>
  );
}
