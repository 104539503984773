import ContainerBox from "./ContainerBox";
import bg from "../docs/bg.jpg";
import { Box, Stack,Typography } from "@mui/material";
import logo from "../docs/logo.png";
const SearchArea = () => {
  return (
    <Box sx={boxStyle}>
      <Box sx={layoutStyle}>
        <Box
          sx={{
            position: "absolute",
            top: 30,
            left: 30,
            background: "white",
            p: 1,
            width: "100px",
            borderRadius : "8px"
          }}
        >
          <img alt="Travis Howard" src={logo} width="100%" />
        </Box>
        <ContainerBox>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={7}
            sx={{ height: "100%" }}
          >
            <Typography variant="h1" component="h1" sx={textStyle}>
              Climate Change Future Watch
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                color: "#fff",
                textAlign: "center",
                width: "70%",
                m: "auto",
                fontSize: {
                  xs: "1rem",
                  md: "1.1rem",
                  lg: "1.2rem",
                  lineHeight: "1.6rem",
                },
              }}
            >
              Climate Change Future Watch (CCFW) is a web-based tool that allows
              users to view alternative climate futures for selected MENA
              countries up to 2050. The tool estimates and generates projections
              of the impacts of various climate-change scenarios on the
              agriculture sector and the effectiveness of policy interventions
              to counter them. It uses different variables like production,
              yield, and area to show the future impact of climate change on
              different food commodities
            </Typography>
          </Stack>
        </ContainerBox>
      </Box>
    </Box>
  );
};

export default SearchArea;

const textStyle = {
  textAlign: "center",
  fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "4.5rem" },
  fontWeight: "800",
  color: "white",
};

const boxStyle = {
  backgroundPosition: "center",
  backgroundImage: `url(${bg})`,
  backgroundSize: "cover",
};

const layoutStyle = {
  height: "80vh",
  minHeight: "500px",
  overflow: "hidden",
  background: "rgba(0, 0, 0, 0.281)",
  position: "relative",
};
