import {  Grid } from "@mui/material";
import MultipleSelector from "./Inputs/MultipleSelector";

const ControlArea = (props) => {
  return (
    <Grid container spacing={2} py={4}>
      <Grid item xs={12}  md={6} lg={4}>
        <MultipleSelector
          options={props.countriesArr}
          changeValHandel={props.countriesValChangeHandel}
          label="Countries"
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <MultipleSelector
          options={props.itemsArr}
          changeValHandel={props.itemsChangeHandel}
          label="Food Commodity"
        />
      </Grid>
      {props.filtersAreaArr.map((el) => (
        <Grid key={el.label} item xs={12} md={6} lg={4}>
          <MultipleSelector
            options={el.optionsArr}
            changeValHandel={props.climateValChangeHandel}
            label={el.label}
            compare={props.compare}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ControlArea;
