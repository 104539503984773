import Climate from "./Pages/Climate";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://dashboard.ccfw.ifpri.cartologic.com/graphql",
  cache: new InMemoryCache(),
});

export function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Climate />
      </ThemeProvider>
    </ApolloProvider>
  );
}
