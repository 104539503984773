import { useEffect, useState } from "react";
import {
  Box,
  Autocomplete,
  Typography,
  Checkbox,
  Chip,
  ListItemButton,
  FormControlLabel,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function MultipleSelector(props) {
  const [val, setVal] = useState([]);

  useEffect(() => {
    if (!props.options[0]) return;
    setVal([props.options[0]]);
    props.changeValHandel([props.options[0]], props.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options]);

  return (
    <>
      <Box noValidate autoComplete="on" sx={{ width: "100%" }}>
        <Typography
          variant="h5"
          size="small"
          sx={{
            color: "text.secondary",
            fontSize: "14px",
            fontWeight: "600",
            textTransform: "capitalize",
          }}
        >
          {props.label.replace("_", " ")}
        </Typography>

        <Autocomplete
          onChange={(e, vals) => {
            setVal(vals);
            props.changeValHandel(vals, props.label);
          }}
          multiple
          size="small"
          disableClearable
          options={props.options}
          value={val}
          disableCloseOnSelect
          getOptionLabel={(el) => el}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((el, index) => (
              <Chip
                label={el}
                {...getTagProps({ index })}
                disabled={val.length < 2 && val.includes(el)}
                sx={{ mx: 0.4, borderRadius: "5px" }}
                variant="outlined"
                color="secondary"
              />
            ))
          }
          renderOption={(props, el, { selected }) => (
            <ListItemButton
              sx={{ width: "100%" }}
              {...props}
              disabled={val.length < 2 && val.includes(el)}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{ mr: 1 }}
                checked={selected}
                color="secondary"
              />
              {el}
            </ListItemButton>
          )}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </Box>
      {(props.label === "climate" || props.label === "policy_intervention") && (
        <FormControlLabel sx={{ "& .MuiTypography-root" :{ fontSize : "15px"}}}
          control={
            <Checkbox
              size="small"
              color="secondary"
              checked={props.compare.vals[props.label]}
              onChange={(event, val) => {
                props.compare.handelChangeCompare(val, props.label);
              }}
              name={props.label}
              disabled={props.compare.disableCompare[props.label]}
            />
          }
          label={"compare with " + props.label.replace("_", " ")}
        />
      )}
    </>
  );
}
