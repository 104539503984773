export const removeDuplicate = (arr, key) => {
  const uniqueArr = arr?.reduce((acc, curr) => {
    if (!acc.length) {
      return [curr[key]];
    } else {
      return acc?.includes(curr[key]) ? acc : [...acc, curr[key]];
    }
  });

  return uniqueArr;
};

export const makeArrFromOpj = (arr, key) => arr?.map((el) => el[key]);
